import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlogCarousel from "./blog/BlogCarousel"

function equalsIgnoreCase(a, b) {
  return a.toUpperCase() === b.toUpperCase()
}
function containsIgnoreCase(stringList) {
  return function (string) {
    return stringList.find((s) => equalsIgnoreCase(s, string))
  }
}

function nodesContainNameIgnoreCase(stringList) {
  return function (nodeList) {
    return nodeList.nodes.map((node) => node.name).find(containsIgnoreCase(stringList))
  }
}

export default function RelatedInnovationBlogPosts({ id, tags, categories }) {
  const results = useStaticQuery(graphql`
    query allInnovationPosts {
      allWpInnovation {
        edges {
          node {
            id
            link
            title
            date(formatString: "MM DD YYYY")
            excerpt
            tags {
              nodes {
                name
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)

  const categoriesContainNameIgnoreCase = nodesContainNameIgnoreCase(categories.nodes.map((node) => node.name).filter((nodeName) => nodeName !== "blog"))
  const tagsContainNameIgnoreCase = nodesContainNameIgnoreCase(tags.nodes.map((node) => node.name))

  const relatedPosts = results.allWpInnovation?.edges
    .filter((post) => post.node.id !== id)
    .filter((post) => categoriesContainNameIgnoreCase(post.node.categories) || tagsContainNameIgnoreCase(post.node.tags))
    .sort((a, b) => {
      return new Date(b.modified) - new Date(a.modified)
    })
    .slice(0, 3)
  return <BlogCarousel blogPosts={relatedPosts.map((post) => post.node)} title="Related Posts" />
}
